.navBar {
    background-color: #ff7f50;
    width: 100%;
    /* height: calc(10px + 2.5vmin); */
    /* height: 25%; */
    display: flex;
    flex-direction: row;
    /* padding: 1vh; */
    font-size: calc(10px + 2vmin);

    justify-content: center;
}

