.entryLogBody {
    width: 100%;
    height: 5%;
    margin: 0%;
    padding: 0%;
    /* display: flex; */
    /* font-family: monospace; */
    text-align: left;
    /* justify-content: center; */
    /* align-items: left;    */
    color: black;
    background-color: rgb(177 178 181 / 80%);
    /* opacity: 50%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 0.1vw black solid; */
    border-bottom: 0.1vh black solid;
    /* overflow: scroll; */
    font-size: calc(7px + 1.2vmin);

    
}

.date {
    position:flex;
    /* top: 0; */
    /* left: 1vw; */
    justify-content: flex-start;
    margin-left: 1%;
}

.content {
    /* justify-content: flex-end; */
    width: 80%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    flex: 0 0 auto; 



    
}

.entryTitle {
    align-self: flex-end;
    margin-right: 3%;
    /* max-height: 15%; */
    /* background-color: aliceblue; */


}

.journalEntry {
    max-height: 7vh;
    overflow-y: hidden

 
}

.webGlScene {
    display: flex;
    flex-direction: row;
    /* overflow-x: scroll; */
}

