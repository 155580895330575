@font-face {
  font-family: 'DMMono';
  src: local('DMMono'), url('./media/DMMono-Regular.ttf') format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.welcome-msg {
  color: black;
  font-family: monospace;
  margin: 0%;
  width: 80%;
  margin-top: calc(7vh + 2vmin);
  font-size: calc(7.5px + 2.25vmin);
  font-family: DMMono;

}

.moon {
  margin-top: 4vh;
  width: calc(10vh + 1.5vmax);
}

body {
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  align-items: center;
  justify-content: center;
}

.App {
  text-align: center;
  
  /* justify-content: center;   */
}

.App-header {
  background-color: #FFA07A;
  /* min-height: 100%; */
  font-family: DMMono;
  width: 100%;
  font-size: calc(10px + 2vmin);

}

.App-link {
  color: #61dafb;
}

