@font-face {
    font-family: 'DMMono';
    src: local('DMMono'), url('../media/DMMono-Regular.ttf') format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

.buttonBody {
    width: 25%;    
    /* height: 6vh; */
    margin: 0%;
    padding: 0%;
    display: flex;
    font-family: DMMono;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: black;
    transition: 0.3s;
    
    /* border: 0.1vw black solid; */
}
a {
    text-decoration: none;
    color: black;
}
.buttonBody:hover {
    background-color: #FFA07A;
    opacity: 0.5;
}
