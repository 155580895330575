.projectBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2vh;
    /* max-height: 50vh; */
    /* width: 75vw; */

    height: calc(240.50px + 30vmin);


    /* height: calc(30px + 40vh); */
}

.projectImage {
    
    max-height: calc(40px + 15vmin);
    /* justify-items: center; */
    align-self: center;
}

.projectExtras {
    padding-top: 1.2vh;

    /* margin-top: 1vh; */
}




